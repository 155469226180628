<template>
    <div class="revenueStrategyBg">
        <div class="bold title">
            站点成长指引
        </div>
        <div>
            <div v-for="(item,index) in infoList" :key="index" class="flex cell_list" >
                <div style="width: 16px;" >
                    <div v-if="index == 0" class="height_full position_relative" style="">
                        <img  :src="require('@/assets/img/siteManage/recenue_active.png')" style="width: 16px;height: 18px;">
                        <div class="position_absolute" v-show="index != infoList.length - 1" style="border-left: dashed 1px #cccccc;left: 7.5px;top: 20px;bottom: -4px;right: 7.5px;z-index: 10"></div>
                    </div>
                    <div v-if="index != 0" class="height_full position_relative" style="">
                        <img  :src="require('@/assets/img/siteManage/recenue_normal.png')" style="width: 10px;height: 10px;margin-left: 3px;">
                        <div class="position_absolute" v-show="index != infoList.length - 1" style="border-left: dashed 1px #cccccc;left: 7.5px;top: 16px;bottom: -4px;right: 7.5px;z-index: 10"></div>
                    </div>
                </div>
                <div style="" class="card-right">
                    <div class="cell_title bold">{{item.title}}</div>
                    <div class="cell_desc">{{item.info}}</div>
                    <div class="cell_amount bold" :style="{'color':item.color }">{{item.tip}}</div>
                </div>
            </div>
        </div>
        <div class="nextBtn">
            <van-button class="btnActive"  slot="button" type="info"  @click="buttonClick()">返回</van-button>
        </div>
    </div>
</template>

<script>
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "revenueStrategy",
        data(){
            return {
                infoList:[{
                    title:'签约1周内',
                    info:'家庭资金转入:主卡转入5万周转金,家庭资金转入',
                    tip:'余额20-30万'
                },{
                    title:'签约1月内',
                    info:'亲友营销:25位以上亲朋,邻居办卡及资金转入',
                    tip:'余额过100万'
                },{
                    title:'签约3月内',
                    info:'基础营销:入户动员,普及发卡覆盖75%家庭以上,并动员资金转入',
                    tip:'余额超过300万'
                },{
                    title:'签约5月内',
                    info:'外围营销:远房亲戚,邻村熟人,城镇经商户,外出务工人员',
                    tip:'余额500万左右'
                },{
                    title:'签约6月内',
                    info:'大户营销:至少2个大户',
                    tip:'余额700万左右'
                },{
                    title:'签约7月内',
                    info:'代发薪:开发周边企事业单位代发薪业务',
                    tip:'余额800万左右'
                },{
                    title:'签约10月内',
                    info:'产业链营销:拓展农业产业链上下游相关客户',
                    tip:'余额突破1000万',
                    color:'#ee5f15'
                },{
                    title:'签约12月内',
                    info:'土地款营销:引入土地拆迁,宅基地买卖,土地流转等款项',
                    tip:'新增少则几十万,多则过千万'
                }]
            }
        },
        mounted() {
            uwStatisticAction('/revenusStrategy','站点成长指引')
        },
        methods:{
            buttonClick(){
                this.$router.back()
            }
        }
    }
</script>

<style lang="less" scoped>
    .revenueStrategyBg{
        background: #F7F8F9;
        padding: 0 0 100px 0;
        .title{
            height: 56px;line-height: 56px;padding-left: 0.7rem;font-size: 18px;color: #272829
        }
        .cell_list{
            padding: 0 0.7rem ;
            .cell_desc{
                font-size: 13px;
                color: #999999;
                padding: 3px 0;
            }
            .cell_amount{
                font-size: 16px;
                color:#3F7C53;
                padding-bottom: 32px;
            }
        }
        .cell_title{
            margin-top: -2px;
            font-size: 16px;
            color: #272829;
        }
        .nextBtn{
            width: 100%;
            padding: 12px;
            background: #F7F8F9;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            .btnActive{
                height:49px;
                line-height: 49px;
                text-align: center;
                background:#FFFFFF;
                border-radius:4px;
                font-size:18px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color:#333333;
                width: 100%;
                opacity:1;
                border: solid 1px #E5E5E5;
            }
        }

        .card-right{
            width: calc(100% - 16px);
            margin-left: 10px;
        }

    }

</style>

